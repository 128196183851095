.loginForm {
    background: #F7F9FA;
    justify-content: 'center';
    align-items: 'center';
    height: auto;
    width: 60%;
    margin: 5em auto;
    color: snow;
    -webkit-box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.4);
    -moz-box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.4);
    box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.4);
}


.col {
    text-align: left;
}


.error {
    border: 2px solid #FF6565;
  }

  .error-message {
    color: #FF6565;
    padding: .5em .2em;
    height: 1em;
    position: absolute;
    font-size: .8em;
  }


.loginForm {
  width: 90%;
  text-align: left;
  padding-top: 2em;
  padding-bottom: 2em;

  @media(min-width: 786px) {
    width: 50%;
  }
}


.lButton {
  font-size: 1.2em;
  font-weight: 400;
  margin: 1em;
}

.loginButton:hover {
    background: #1D3461;
}

.fgroup {
    width: auto;
    padding-left: 2em;
  }

  .loginDiv {
    z-index: -1;
  }

  .lError {
    color: #bd2a2a;
    
  }

  .lLabel {
    color: #555;
  }

  .h2Heading {
    text-align: center;
    padding-bottom: 1.5ex;
  }