.container {
    height: 840px;
    width: 600px;
    background-color: #ffffff;
    box-shadow: 0 0 25px rgba(17, 1, 68, 0.08);
    border-radius: 8px;
    position: relative;
    cursor: pointer;
    display:flex;
    padding: 30px;
  }
  .Img-Div {
    width: 90%;
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    cursor: pointer;
  }
  input[type="checkbox"] {
    -webkit-appearance: none;
    position: relative;
    width: 100%;
    cursor: pointer;
  }
  input[type="checkbox"]:after {
    position: absolute;
    font-family: "Font Awesome 5 Free";
    font-weight: 400;
    content: "\f111";
    font-size: 18px;
    color: #478bfb;
    right: 10px;
    top: -5px;
  }
  input[type="checkbox"]:checked:after {
    font-weight: 900;
    content: "\f058";
    color: #478bfb;
  }

.imgCheck {
    z-index:-1;
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0.5;
    color:#00ff37;
}

.imgCheck.selected {
    z-index:10;
}

.textInput {
    width: 300px;
}

.my-loader {
  width: 4rem;
  height: 4rem;
}

.page {
  width:70%;
  align-self: center;
  justify-content: center;
  margin: auto;
}